<template>
  <div class="btc-etf-container">
    <div class="container-header">
      <div class="b-l">{{ hDate }}现货ETF速览</div>
      <div class="b-r">
        <span> 今日BTC价格：{{ hPrice }}美元</span>
      </div>
    </div>
    <div class="etf-table">
      <el-table style="width: 100%; background-color: transparent; border-radius: 4px" :data="tableData"
        class="etf-data-table" :header-cell-style="{
          background: 'rgba(45, 47, 58, 1)',
          color: 'rgba(239, 195, 148, 1)',
        }" border>
        <el-table-column prop="name" label="ETF名称" width="500" align="center">
          <template slot-scope="scope">
            <div>
              {{ scope.row.name + "(" + scope.row.ticker + ")" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="markPrice" label="市场价格" align="center">
          <template slot-scope="scope">
            <el-popover placement="top-start" width="320" height="200px" trigger="hover" popper-class="pop-chart"
              :visible-arrow="false">
              <div style="width: 100%">
                <Chart ref="chart3" class="chart" width="320px" height="200px" :option="getdailyChangeChart(scope.row)"
                  :loading="loading" />
              </div>
              <div slot="reference" style="cursor: pointer">
                {{ scope.row.markPrice }}$<span :style="{
                    color:
                      scope.row.dailyChange >= 0
                        ? 'rgba(0, 174, 147, 1)'
                        : 'rgba(255, 82, 96, 1)',
                  }">({{
                  parseFloat(scope.row.dailyChange * 100).toFixed(2) + "%"
                  }})</span>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="nav" label="净值价格" align="center">
          <template slot-scope="scope">
            <div>
              {{ parseFloat(scope.row.nav).toFixed(2) }}
            </div></template
          >
        </el-table-column> -->
        <el-table-column prop="fee" label="溢价率" align="center">
          <template slot-scope="scope">
            <el-popover placement="top-start" width="320" height="200px" trigger="hover" popper-class="pop-chart"
              :visible-arrow="false">
              <div style="width: 100%">
                <Chart ref="chart3" class="chart" width="320px" height="200px" :option="getFeeChartData(scope.row)"
                  :loading="loading1" />
              </div>
              <div slot="reference" style="cursor: pointer" :style="{
                  color:
                    scope.row.fee >= 0
                      ? 'rgba(0, 174, 147, 1)'
                      : 'rgba(255, 82, 96, 1)',
                }">
                {{ parseFloat(scope.row.fee * 100).toFixed(2) + "%" }}
              </div>
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column prop="assets" label="比特币数量(个)" align="center">
          <template slot-scope="scope">
            <div>
              {{ formatNum(scope.row.btcCount) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="assets" label="ETF资金规模(USD)" align="center">
          <template slot-scope="scope">
            <div>
              {{ formatNum(scope.row.assets) }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <b-row class="">
      <b-col lg="6">
        <b-card no-body style="height: 460px">
          <b-card-header>
            <b-card-title class="">
              <div class="header-title">
                <div class="a-t">交易量 <span>（$Mlns）</span></div>
                <!-- <a href="" target="_blank"> </a> -->
              </div>
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <div class="select-container" style="">
              <div class="se-tit-mar">总交易量{{ tradeinfoSum }}亿美元</div>
              <div class="chart-option filter">
                <div class="box-he">
                  <div class="dotmapDate">
                    <el-date-picker popper-class="dotmapPicker" v-model="tradeinfoDate" value-format="yyyy-MM-dd"
                      type="date" size="small" width="120" placeholder="选择日期" :picker-options="expireTimeOption"
                      @change="getTimeChange">
                    </el-date-picker>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div style="margin-top: 12px; text-align: center">
              总交易量{{ tradeinfoSum }}亿美元
            </div> -->
            <div class="container-body">
              <Chart ref="chart" class="chart" :option="tradingVolumeOption" :height="height" :loading="loading3" />
            </div>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col lg="6">
        <b-card no-body style="height: 460px">
          <b-card-header>
            <b-card-title class="">
              <div class="header-title">
                <div class="a-t">ETF资金规模 <span>（$Mlns）</span></div>
              </div>
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <div class="select-container">
              <div class="se-tit">总ETF资金规模{{ fundSizelSum }}亿美元</div>
            </div>
            <div class="container-body" style="margin-top: 12px">
              <Chart ref="chart1" class="chart" :option="fundSizeOption" :height="height" :loading="loading4" />
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-card no-body>
      <b-card-header>
        <b-card-title>
          <div class="header-title">
            <div class="a-t">资产规模及BTC持有量</div>
            <!-- <a href="" target="_blank"></a> -->
          </div>
        </b-card-title>
      </b-card-header>
      <b-card-body>
        <div class="select-container" style="justify-content: flex-end">
          <!-- <div class="se-tit" style="width: 100%; text-align: center">
            {{ etfNum }}只ETF资产规模290.14亿美元，BTC持有量63.67万枚
          </div> -->
          <div class="chart-option filter">
            <el-select class="etfSelect" v-model="assetSize" placeholder="" @change="timeChange($event)">
              <el-option v-for="item in dataOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="container-body">
          <Chart ref="chart2" class="chart" :option="etfAssetSizeOption" :loading="loading5" />
        </div>
      </b-card-body>
    </b-card>
    <b-card no-body>
      <b-card-header>
        <b-card-title>
          <div class="header-title">
            <div class="a-t">ETF资金规模</div>
            <!-- <a href="" target="_blank"></a> -->
          </div>
        </b-card-title>
      </b-card-header>
      <b-card-body>
        <div class="container-body">
          <Chart ref="chart3" class="chart" :height="height2" :option="etfStackOption" :loading="loading6" />
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
  import {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
  } from "bootstrap-vue";
  import Chart from "../charts-and-maps/charts/chart.vue";
  import {
    fromatterPieOptions,
    number2String,
    graphic,
    graphicBig,
    SPLIT_LINE,
    NOT_DATA_OPTION,
  } from "../../utils/chart";
  export default {
    components: {
      BRow,
      BCol,
      BCard,
      BCardBody,
      BCardHeader,
      BCardTitle,
      Chart,
    },
    data() {
      return {
        hDate: "2024年1月15日",
        hPrice: "46342",
        percentagePrice: "-0.69",
        assetSize: "",
        dataOptions: [],
        tableData: [],
        expireTimeOption: {
          disabledDate(date) {
            // disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
            return date.getTime() >= Date.now();
          },
        },
        selectDate: "",
        selectDate2: "",
        changeColor: "",
        topFivePlusOther: [],
        fundSizeData: [],
        isDis: true,
        tradeinfoDate: "",
        etfAssetSizeOption: {},
        tradingVolumeOption: {},
        fundSizeOption: {},
        height: "356px",
        height2: "400px",
        assetData: [],
        assetChangeData: [],
        btcCountData: [],
        xDate: [],
        tradeinfoSum: "",
        fundSizelSum: "",
        etfNum: "",
        etfStackOption: {},
        processedArr: {},
        textStyle: {
          fontSize: 12,
          color: "#D1D4DC",
        },
        loading: true,
        loading1: true,
        loading2: true,
        loading3: true,
        loading4: true,
        loading5: true,
        loading6: true,
      };
    },
    computed: {
      imageSource() {
        if (parseFloat(this.percentagePrice) >= 0) {
          return require("@/assets/images/rtaLogo/btc-etf/rise.svg");
        } else {
          return require("@/assets/images/rtaLogo/btc-etf/fall.svg");
        }
      },
    },
    created() {
      this.getEtfTData();

      this.getEtfFundSizeData();
      //this.getTradeTradeinfo();
      this.getTickerData();

      this.findEtfAssetSizeOption();
      this.getEtfBaseinfoData();
      // //获取交易量筛选默认时间 this.tradeinfoDate
      // let currentDate = new Date();
      // // if (
      // //   currentDate.getHours() < 20 ||
      // //   (currentDate.getHours() === 20 && currentDate.getMinutes() < 2)
      // // ) {
      // currentDate.setDate(currentDate.getDate() - 1);
      // // } else {
      // // }
      // this.tradeinfoDate = currentDate.toISOString().split("T")[0];
      this.tradeinfoDate;
      this.getEtfTradeinfoData();
    },
    mounted() { },
    methods: {
      //获取本地时间
      formatDate(date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        return `${year}年${month}月${day}日`;
      },
      timeChange(item, label) {
        this.assetSize = item;
        this.getTradeTradeinfo();
      },
      //请求ETF速览全部数据
      getEtfBaseinfoData() {
        this.axios
          .get("https://trade.rta.academy/trade/etf/baseinfo/")
          .then((res) => {
            if (res.data.code == "0") {
              let tempData = res.data.data;
              tempData.sort((a, b) => b.assets - a.assets);
              this.tableData = tempData;
              //console.log(this.tableData);
              let tickerArr = this.tableData.map((v) => v.ticker);
              this.dataOptions = [
                // {
                //   value: "",
                //   label: "全部",
                // },
                ...tickerArr.map((item) => ({
                  value: item,
                  label: item,
                })),
              ];
              if (this.dataOptions.length > 0) {
                this.assetSize = this.dataOptions[0].value; // 将第一个选项的value值赋给assetSize
              }
              //   console.log("dataOptions:", arr, this.dataOptions);
              this.getTradeTradeinfo();
            } else {
            }
          })
          .catch((err) => { })
          .finally(() => {
            this.loading = false; // 确保在数据加载完成后隐藏加载动画
            this.loading1 = false;
            this.loading2 = false;
          });
      },
      getTimeChange(value) {
        this.tradeinfoDate = value;
        this.topFivePlusOther = [];
        this.getEtfTradeinfoData();
      },
      //请求交易量全部数据
      getEtfTradeinfoData() {
        const params = {
          queryType: "trade",
          queryDate: this.tradeinfoDate,
        };
        this.axios
          .get("https://trade.rta.academy/trade/etf/tradeinfo/", { params })
          .then((res) => {
            if (res.data.code == "0") {
              if (res.data.data == []) {
                this.topFivePlusOther = [];
              } else {
                let fundsData = res.data.data;
                fundsData.sort((a, b) => b.trading - a.trading);
                // 计算除前五项之外的 "其他" 类别的总和
                let otherSum = fundsData
                  .slice(5)
                  .reduce((sum, item) => sum + item.trading, 0);

                let bbb = fundsData.reduce((sum, item) => sum + item.trading, 0);
                this.tradeinfoSum = (parseFloat(bbb) / 100000000).toFixed(2);

                // 获取前五个基金并添加 "其他" 类别
                this.topFivePlusOther = fundsData.slice(0, 5).map((item) => {
                  return { value: item.trading, name: item.ticker };
                });

                if (otherSum > 0) {
                  this.topFivePlusOther.push({ value: otherSum, name: "其他" });
                }
              }
              this.findTrdingData();
            } else {
            }
          })
          .catch((err) => { })
          .finally(() => {
            this.loading3 = false; // 确保在数据加载完成后隐藏加载动画
          });
      },
      //交易量数据展示
      findTrdingData() {
        let topFivePlusOther = this.topFivePlusOther;
        this.tradingVolumeOption = fromatterPieOptions(topFivePlusOther);
      },
      //请求基金规模全部数据
      getEtfFundSizeData() {
        const params = {
          queryType: "asset",
        };
        this.axios
          .get("https://trade.rta.academy/trade/etf/tradeinfo/", { params })
          .then((res) => {
            if (res.data.code == "0") {
              if (res.data.data == []) {
                this.FundSizeData = [];
              } else {
                let tempData = res.data.data;
                tempData.sort((a, b) => b.trading - a.trading);
                // 计算除前五项之外的 "其他" 类别的总和
                let otherSum = tempData
                  .slice(5)
                  .reduce((sum, item) => sum + item.trading, 0);
                let bbb = tempData.reduce((sum, item) => sum + item.trading, 0);
                this.fundSizelSum = (parseFloat(bbb) / 100000000).toFixed(2);

                // 获取前五个基金并添加 "其他" 类别
                this.fundSizeData = tempData.slice(0, 5).map((item) => {
                  return { value: item.trading, name: item.ticker };
                });
                if (otherSum > 0) {
                  this.fundSizeData.push({ value: otherSum, name: "其他" });
                }
              }
              this.findFundSize();
            } else {
            }
          })
          .catch((err) => { })
          .finally(() => {
            this.loading4 = false; // 确保在数据加载完成后隐藏加载动画
          });
      },
      //基金规模数据展示
      findFundSize() {
        let fundSizeData = this.fundSizeData;
        this.fundSizeOption = fromatterPieOptions(fundSizeData);
      },
      findBitmapInformationData() {
        this.selectDate2 = "2023-08-08";
        const aaa = this.dateTypeFormat("YYYY-mm-dd", new Date());
        if (this.selectDate2 < aaa) {
          this.changeColor = "rgba(rgba(255, 255, 255, 1))";
          this.isDis == false; //2023-03-14
        } else {
          this.isDis == true;
          this.changeColor = "rgba(209,212,220,0.5)";
          // this.$refs.downRef.style.
        }
      },
      //获取btc收盘下拉选项跟btc当日价格
      getTickerData() {
        this.axios
          .get("https://trade.rta.academy/trade/etf/ticker/")
          .then((res) => {
            this.hPrice = res.data.data.closePrice;
            let arr = res.data.data.tickers;
            this.etfNum = arr.length;
            //   this.dataOptions = [
            //     // {
            //     //   value: "",
            //     //   label: "全部",
            //     // },
            //     ...arr
            //       .map((item) => ({
            //         value: item,
            //         label: item,
            //       }))
            //       .reverse(),
            //   ];
            //   if (this.dataOptions.length > 0) {
            //     this.assetSize = this.dataOptions[0].value; // 将第一个选项的value值赋给assetSize
            //   }
            //   //   console.log("dataOptions:", arr, this.dataOptions);
            //   this.getTradeTradeinfo();
          })
          .catch((err) => { });
      },
      //获取资产规模及BTC持有量全部数据
      getTradeTradeinfo() {
        const params = {
          queryCoin: this.assetSize,
        };
        this.axios
          .get("https://trade.rta.academy/trade/etf/tradeinfo/", { params })
          .then((res) => {
            let tempData = res.data.data;
            this.assetData = tempData.map((v) => v.asset);
            this.assetChangeData = tempData.map((v) => v.assetChange);
            this.btcCountData = tempData.map((v) => v.btcCount);
            this.xDate = tempData.map((v) =>
              this.dateTypeFormat("YYYY-mm-dd ", new Date(v.date))
            );

            this.tradeinfoDate = this.dateTypeFormat(
              "YYYY-mm-dd ",
              new Date(tempData[tempData.length - 1].date)
            );
            this.findEtfAssetSizeOption();
          })
          .catch((err) => { })
          .finally(() => {
            this.loading5 = false; // 确保在数据加载完成后隐藏加载动画
          });
      },

      //echarts专用求最大公约数 不含小数
      chartlcm(a, b) {
        var result = 1;
        for (var i = 1; i <= a && i <= b; i++) {
          if (a % i == 0 && b % i == 0) {
            result = i;
          }
          if (result > 1 && i >= 10)
            //公约数大于10的时候 直接跳出 避免y轴刻度太多  (如果不介意刻度太多可以把这一段去掉）
            break;
        }
        return result;
      },
      //获取echarts  多Y轴的最大值和间隔值 lcmval 最大公约数   divisor 间隔数量
      YmaxvalAndinterval(m, n, lcmval, divisor) {
        var interval1 = Math.ceil(m / lcmval);
        var interval2 = Math.ceil(n / lcmval);
        if (lcmval != 1 && lcmval != 0 && lcmval <= 10) {
          return { max1: m, max2: n, interval1: interval1, interval2: interval2 };
        }
        if (divisor == undefined || divisor == null) divisor = 5;
        //var mval = m % divisor;
        //if (mval > 0) {
        //    m = ((m - mval) / divisor + 1) * divisor
        //}
        m = Math.ceil(m / divisor) * divisor;
        n = Math.ceil(n / divisor) * divisor;
        interval1 = Math.ceil(m / divisor);
        interval2 = Math.ceil(n / divisor);
        return { max1: m, max2: n, interval1: interval1, interval2: interval2 };
      },
      //资产规模及BTC持有量数据展示
      findEtfAssetSizeOption() {
        const xData = this.xDate;
        const cyData = this.assetData;
        const ayData = this.assetChangeData;
        const byData = this.btcCountData;
        // 获取最大值
        var maxValue = Math.max.apply(null, cyData);

        // 获取最小值
        var minValue = Math.min.apply(null, cyData);
        // 获取最大值
        var maxValue2 = Math.max.apply(null, byData);

        // 获取最小值
        var minValue2 = Math.min.apply(null, byData);
        //yMax1 第一条y轴的最大值   yMax2 第二条y轴的最大值
        var divisor = 5;
        var lcmVal = this.chartlcm(maxValue, maxValue2); //获取两条y轴的最大公约数
        var Ymaxval_interval = this.YmaxvalAndinterval(
          maxValue,
          maxValue2,
          lcmVal,
          divisor
        ); //计算y轴最大值和间隔值

        // 获取最后一个日期字符串
        const lastDateString = xData[xData.length - 1];

        // 将日期字符串转换为Date对象
        let currentDate = new Date(lastDateString);

        // // 需要补充的天数
        // const daysToAdd = 20;

        // // 循环添加剩余的日期
        // for (let i = 1; i <= daysToAdd; i++) {
        //   // 为当前日期加1天
        //   currentDate.setDate(currentDate.getDate() + 1);

        //   // 将新日期格式化并添加到数组中
        //   const formattedDate = this.dateTypeFormat(
        //     "YYYY-mm-dd HH:MM",
        //     currentDate
        //   );
        //   xData.push(formattedDate);

        //   // 同时需要在其他相关的数组中补充对应的空数据
        //   cyData.push(null);
        //   ayData.push(null);
        //   byData.push(null);
        // }

        // // 更新processedArr的日期列表
        // this.xDate = xData;

        if (xData != []) {
          this.etfAssetSizeOption = {
            backgroundColor: "#33353E",
            tooltip: {
              trigger: "axis",
              borderWidth: "0", //边框宽度设置1
              borderColor: "none", //设置边框颜色
              padding: 16,
              textStyle: this.textStyle,
              tooltip: {
                show: true,
              },
              extraCssText: "background:rgba(61, 63, 71, 1);",
              formatter: (params) => {
                let hasData = false;

                // 假设params数组中存在至少一个非空的数据项
                for (let i = 0; i < params.length; i++) {
                  if (params[i].data !== null) {
                    hasData = true;
                    break;
                  }
                }

                // 如果所有项都是空数据，则不显示tooltip
                if (!hasData) {
                  return "";
                }
                let weekDays = this.weekDay(params[0].name);
                let result = `<div style="border-bottom: 1px solid rgba(151, 151, 151, 0.2); padding-bottom: 8px; margin-bottom: 8px">
                   ${params[0].name}
                  </div>`;
                params.forEach((item) => {
                  var re = item.seriesName;
                  var colors = "";
                  switch (re) {
                    case "资产规模（$)":
                      colors = "rgba(255, 134, 0, 1)";
                      break;
                    case "每日资产变化（$  Mlns)":
                      colors = "rgba(74, 181, 232, 1)";
                      break;
                    case "BTC持有量（个）":
                      colors = "rgba(234, 204, 174, 1)";
                      break;
                  }
                  var aaa = this.kmb(item.data);
                  result += `<span style="color:${colors}">${item.seriesName} : ${aaa}</span><br/>  `;
                });
                return result;
              },
            },
            grid: {
              left: "0%",
              right: "0%",
              // bottom: "3%",
              containLabel: true,
            },
            legend: {
              textStyle: this.textStyle,

              itemWidth: 12,
              // itemHeight: 2,
              itemGap: 40,
              data: [
                {
                  name: "资产规模（$)",
                  icon: "path://M0,0 L20,0 L20,3 L0,3 Z",
                  // icon: "rect",
                },
                // {
                //   name: "每日资产变化（$  Mlns)",
                //   icon: "path://M0,5 A5,5 0 0 1 5,0 L10,0 A5,5 0 0 1 15,5 L15,10 A5,5 0 0 2 10,15 L5,15 A5,5 0 0 1 0,10 Z",
                // },
                {
                  name: "BTC持有量（个）",
                  icon: "path://M0,5 A5,5 0 0 1 5,0 L10,0 A5,5 0 0 1 15,5 L15,10 A5,5 0 0 1 10,15 L5,15 A5,5 0 0 1 0,10 Z",
                },
              ],
            },
            xAxis: [
              {
                type: "category",
                data: this.xDate,
                axisPointer: {
                  type: "shadow",
                },
                axisLabel: {
                  showMaxLabel: true,
                  // rotate: 60,
                  textStyle: {
                    color: "rgba(209, 212, 220, 1)",
                  },
                  formatter: function (value) {
                    // 将value（时间值）转换为指定格式的字符串
                    const date = new Date(value);
                    const month = date.getMonth() + 1; // 月份从0开始，需要加1
                    const day = date.getDate();

                    return month + "-" + day;
                  },
                },
              },
            ],
            yAxis: [
              {
                type: "value",
                splitNumber: 5,
                min: 0, //最小值
                max: Ymaxval_interval.max1, //最大值
                interval: Ymaxval_interval.interval1, //每个刻度的间隔值

                axisLabel: {
                  formatter: function (v) {
                    if (v > 1000 && v <= 1000000) {
                      return (v / 1000).toFixed(0) + "K";
                    } else if (v > 1000000 && v <= 1000000000) {
                      return (v / 1000000).toFixed(0) + "M";
                    } else if (v > 1000000000) {
                      return (v / 1000000000).toFixed(0) + "B";
                    } else {
                      return v.toFixed(0);
                    }
                    // return v + 'K';
                  },
                  textStyle: {
                    color: "rgba(209, 212, 220, 0.80)",
                  },
                  margin: 20,
                },
                splitLine: SPLIT_LINE,
              },
              {
                type: "value",
                splitNumber: 5,
                min: 0,
                max: Ymaxval_interval.max2,
                interval: Ymaxval_interval.interval2,
                // interval: function (value, index) {
                //   const range = value.max - value.min;
                //   const splitNum = 5; // 刻度线的数量，保持与第一个y轴一致
                //   return range / splitNum;
                // },
                axisLabel: {
                  formatter: function (v) {
                    const absV = Math.abs(v); // 将数值取绝对值
                    let result = "";
                    if (absV >= 1000000000) {
                      result = (absV / 1000000000).toFixed(0) + "B";
                    } else if (absV >= 1000000) {
                      result = (absV / 1000000).toFixed(0) + "M";
                    } else if (absV >= 1000) {
                      result = (absV / 1000).toFixed(0) + "K";
                    } else {
                      result = absV.toFixed(0);
                    }

                    if (v < 0) {
                      result = "-" + result;
                    }

                    return result;
                  },
                  textStyle: {
                    color: "rgba(209, 212, 220, 0.80)",
                  },
                  margin: 20,
                },
                splitLine: SPLIT_LINE,
              },
            ],
            dataZoom: [
              {
                show: true,
                start: 40,
                end: 100
              },
              {
                type: 'inside',
                start: 40,
                end: 100
              },],
            series: [
              {
                name: "资产规模（$)",
                type: "line",
                //   smooth: true,
                symbolSize: 0, //小圆点
                z: 10,
                yAxisIndex: 0,
                data: cyData,
                itemStyle: {
                  color: "rgba(255, 134, 0, 1)",
                },
              },
              // {
              //   name: "每日资产变化（$  Mlns)",
              //   barMinHeight: 2,
              //   type: "bar",
              //   z: 5,
              //   yAxisIndex: 1,
              //   data: ayData,
              //   itemStyle: {
              //     color: "rgba(74, 181, 232, 1)",
              //   },
              //   emphasis: {
              //     focus: "series",
              //   },
              // },
              {
                name: "BTC持有量（个）",
                type: "bar",
                barMinHeight: 2,
                z: 5,
                yAxisIndex: 1,
                data: byData,
                itemStyle: {
                  // color: "rgba(234, 204, 174, 1)",
                  color: {
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0.1,
                        color: "rgba(234, 204, 174, 1)", // 0% 处的颜色
                      },
                      {
                        offset: 0.9,
                        color: "rgba(247, 224, 189, 0)", // 100% 处的颜色
                      },
                    ],
                    globalCoord: false, // 缺省为 false
                  },
                },
                emphasis: {
                  focus: "series",
                },
              },
            ],
            graphic: graphicBig("echarts-bg_yYgTFo1.png"),
          };
        } else {
          //暂无数据
          this.expirationOption = NOT_DATA_OPTION;
        }
      },
      //获取btf全部数据
      getEtfTData() {
        const params = {
          queryType: "all",
        };
        this.axios
          .get("https://trade.rta.academy/trade/etf/tradeinfo/", { params })
          .then((res) => {
            if (res.data.code == "0") {
              //console.log("获取btf全部数据", res.data.data);
              const rawData = res.data.data;
              const processedData = {
                IBIT: [],
                FBTC: [],
                GBTC: [],
                其他: [],
                dates: [],
              };
              rawData.forEach((entry) => {
                const date = Object.keys(entry)[0];
                //"YYYY-mm-dd HH:MM"
                const formattedDate = this.dateTypeFormat(
                  "YYYY-mm-dd HH:MM",
                  new Date(date)
                );
                processedData.dates.push(formattedDate);

                let otherAssets = 0;
                let otherBtcCount = 0;

                entry[date].forEach((item) => {
                  if (
                    item.ticker === "IBIT" ||
                    item.ticker === "FBTC" ||
                    item.ticker === "GBTC"
                  ) {
                    processedData[item.ticker].push(item.asset);
                  } else {
                    otherAssets += item.asset;
                    otherBtcCount += item.btcCount;
                  }
                });

                processedData.其他.push(otherAssets);
              });

              this.processedArr = processedData;
              this.findTradeinfo();
            } else {
            }
          })
          .catch((err) => { })
          .finally(() => {
            this.loading6 = false; // 确保在数据加载完成后隐藏加载动画
          });
      },
      findTradeinfo() {
        const processedData = this.processedArr;
        // console.log(processedData);
        // 处理数据，计算每个时间点的总和
        const totals = processedData.dates.map((date, index) => {
          return [
            processedData.IBIT[index],
            processedData.FBTC[index],
            processedData.GBTC[index],
            processedData.其他[index],
          ].reduce((a, b) => a + b, 0);
        });
        //临时因为数据太少补足20天数据
        const dates = processedData.dates;
        // 获取最后一个日期字符串
        const lastDateString = dates[dates.length - 1];

        // 将日期字符串转换为Date对象
        let currentDate = new Date(lastDateString);
        // // 需要补充的天数
        // const daysToAdd = 20;
        // // 循环添加剩余的日期
        // for (let i = 1; i <= daysToAdd; i++) {
        //   // 为当前日期加1天
        //   currentDate.setDate(currentDate.getDate() + 1);

        //   // 将新日期格式化并添加到数组中
        //   const formattedDate = this.dateTypeFormat(
        //     "YYYY-mm-dd HH:MM",
        //     currentDate
        //   );
        //   dates.push(formattedDate);

        //   // 同时需要在其他相关的数组中补充对应的空数据
        //   processedData.IBIT.push(null);
        //   processedData.FBTC.push(null);
        //   processedData.GBTC.push(null);
        //   processedData.其他.push(null);
        // }
        // // 更新processedArr的日期列表
        // this.processedArr.dates = dates;
        //console.log(processedData);
        if (processedData.其他.length == 0) {
          //暂无数据
          this.etfStackOption = NOT_DATA_OPTION;
        } else {
          this.etfStackOption = {
            tooltip: {
              trigger: "axis",
              borderWidth: "0", //边框宽度设置1
              borderColor: "none", //设置边框颜色
              padding: 16,
              textStyle: this.textStyle,
              tooltip: {
                show: true,
              },
              extraCssText: "background:rgba(61, 63, 71, 1);",
              formatter: (params) => {
                // 过滤掉名为'Total'的系列数据
                params = params.filter((param) => param.seriesName !== "Total");

                let total = 0;
                // 重新计算total为过滤后的数据总和
                params.forEach((item) => {
                  if (item.data !== null) {
                    total += item.data;
                  }
                });

                let hasData = total !== 0;
                if (!hasData) {
                  return "";
                }

                let weekDays = this.weekDay(params[0].name);
                let result = `<div style="border-bottom: 1px solid rgba(151, 151, 151, 0.2); padding-bottom: 8px; margin-bottom: 8px">
                   ${weekDays}, ${params[0].name}
                  </div>`;
                params.forEach((item) => {
                  var re = item.seriesName;
                  var colors = "";
                  switch (re) {
                    case "FBTC":
                      colors = "#91CC76";
                      break;
                    case "GBTC":
                      colors = "rgba(59, 162, 114, 1)";
                      break;
                    case "IBIT":
                      colors = "#329FD4 ";
                      break;
                    case "其他":
                      colors = "#FC8452";
                      break;
                  }
                  var aaa = this.kmb(item.data);
                  var percentage = ((item.data / total) * 100).toFixed(2) + "%"; // 计算百分比
                  result += `<span style="color:${colors}">${item.seriesName} : ${aaa} (${percentage})</span><br/>`;
                });
                return result;
              },
            },
            grid: {
              left: "0%",
              right: "2%",
              // bottom: "3%",
              containLabel: true,
            },
            legend: {
              top: 20,
              textStyle: this.textStyle,
              padding: [0, 32, 0, 0],
              itemHeight: 12,
              itemWidth: 12,
              itemGap: 40,
              data: [
                {
                  name: "GBTC",
                  icon: "roundRect",
                },
                {
                  name: "IBIT",
                  icon: "roundRect",
                },
                {
                  name: "FBTC",
                  icon: "roundRect",
                },

                {
                  name: "其他",
                  icon: "roundRect",
                },
              ],
            },
            dataZoom: [
              {
                show: true,
                start: 40,
                end: 100
              },
              {
                type: 'inside',
                start: 40,
                end: 100
              },],
            xAxis: [
              {
                type: "category",
                data: this.processedArr.dates,
                axisPointer: {
                  type: "shadow",
                },
                axisLabel: {
                  showMaxLabel: true,
                  // rotate: 60,
                  textStyle: {
                    color: "rgba(209, 212, 220, 1)",
                  },
                  formatter: function (value) {
                    const parts = value.split(" ")[0].split("-");
                    return `${parts[1]}-${parts[2]}`; // 返回 'mm-dd' 格式
                  },
                },
              },
            ],
            yAxis: [
              {
                type: "value",
                axisLabel: {
                  formatter: function (v) {
                    if (v > 1000 && v <= 1000000) {
                      return v / 1000 + "K";
                    } else if (v > 1000000 && v <= 1000000000) {
                      return v / 1000000 + "M";
                    } else if (v > 1000000000) {
                      return v / 1000000000 + "B";
                    } else {
                      return v;
                    }
                    // return v + 'K';
                  },
                  textStyle: {
                    color: "rgba(209, 212, 220, 1)",
                  },
                  margin: 20,
                },
                splitLine: SPLIT_LINE,
              },
            ],
            series: [
              {
                name: "GBTC",
                type: "bar",
                stack: "total",
                data: processedData.GBTC,
                label: {
                  show: false,
                },
                itemStyle: {
                  color: "#3BA272",
                },
              },
              {
                name: "IBIT",
                type: "bar",
                stack: "total",
                label: {
                  show: false,
                },
                data: processedData.IBIT,
                itemStyle: {
                  color: "#329FD4 ",
                },
              },
              {
                name: "FBTC",
                type: "bar",
                stack: "total",
                label: {
                  show: false,
                },
                data: processedData.FBTC,
                itemStyle: {
                  color: "#91CC76",
                },
              },

              {
                name: "其他",
                type: "bar",
                stack: "total",
                data: processedData.其他,
                label: {
                  show: false,
                },
                itemStyle: {
                  color: "#FC8452",
                },
              },
              {
                name: "Total",
                type: "bar",
                stack: "total",
                label: {
                  show: true,
                  formatter: (params) => {
                    // 只有当对应的总和大于0时才显示
                    if (totals[params.dataIndex] > 0) {
                      return this.kmb(totals[params.dataIndex]);
                    }
                    return "";
                  },
                  position: "top",
                  padding: [0, 0, 5, 0],
                  color: "#D8D8D8",
                  fontSize: 12, // 字体大小
                  fontWeight: "normal",
                  fontFamily: "Roboto-Regular, Robot", // 字体系列
                },

                data: Array(processedData.dates.length).fill(0),
              },
            ],
            graphic: graphicBig("echarts-bg_yYgTFo1.png"),
          };
        }
      },
      getdailyChangeChart(row) {
        let template = row.markPriceList;
        const markPriceDate = template.map((item) => Object.keys(item)[0]);
        const markPriceValue = template.map((item) => item[Object.keys(item)[0]]);
        let dailyChangeTittle = row.name + "(" + row.ticker + ")";
        // const sharwOutstandingOption

        return {
          title: {
            text: dailyChangeTittle,
            left: "center",
            textStyle: {
              fontSize: 14,
              fontWeight: "normal",
              color: "rgba(209, 212, 220, 1)",
            },
            subtextStyle: {
              fontSize: 14,
            },
          },
          tooltip: {
            trigger: "axis",
            borderWidth: "0", //边框宽度设置1
            borderColor: "none", //设置边框颜色
            formatter: (params) => {
              let result = `<div style="border-bottom: 1px solid rgba(151, 151, 151, 0.2); padding-bottom: 8px; margin-bottom: 8px">
                    ${params[0].name}
                  </div>`;

              params.forEach((item) => {
                var aaa = item.value.toFixed(2);
                result += `<span style="color:#EACCAE">${item.seriesName} : ${aaa}$</span></br>`;
              });
              return result;
            },
            padding: 20,
            textStyle: this.textStyle,
            tooltip: {
              show: true,
            },
            extraCssText: "background:rgba(61, 63, 71, 1);",
          },
          grid: {
            top: "20%",
            left: "2%",
            right: "8%",
            bottom: "0",
            containLabel: true,
          },
          xAxis: [
            {
              type: "category",
              data: markPriceDate,
              axisPointer: {
                type: "shadow",
              },
              axisTick: {
                show: false, // 隐藏y轴的刻度线
              },
              axisLabel: {
                showMaxLabel: true,
                // rotate: 60,
                textStyle: {
                  color: "rgba(209, 212, 220, 1)",
                },
                formatter: function (value) {
                  const parts = value.split(" ")[0].split("-");
                  return `${parts[1]}-${parts[2]}`; // 返回 'mm-dd' 格式
                },
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              axisLabel: {
                formatter: function (v) {
                  if (v > 1000 && v <= 1000000) {
                    return v / 1000 + "K";
                  } else if (v > 1000000 && v <= 1000000000) {
                    return v / 1000000 + "M";
                  } else if (v > 1000000000) {
                    return v / 1000000000 + "B";
                  } else {
                    return v;
                  }
                  // return v + 'K';
                },
                fontWeight: "normal",
                fontSize: 12,
                textStyle: {
                  color: "rgba(209, 212, 220, 1)",
                },
                // margin: 20,
              },

              splitLine: {
                show: true,
                lineStyle: {
                  type: "dashed",
                  color: "rgba(255, 255, 255, 0.1)",
                },
              },
            },
          ],
          series: [
            {
              name: dailyChangeTittle,
              type: "line",
              stack: "total",
              data: markPriceValue,
              label: {
                show: false,
              },
              symbol: "circle",
              showSymbol: false,
              lineStyle: {
                color: "rgba(237, 189, 136, 1)",
              },
              itemStyle: {
                normal: {
                  color: "rgba(237, 189, 136, 1)",
                },
              },
              areaStyle: {
                normal: {
                  color: {
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0.1,
                        color: "rgba(237,189,136,0.2000)", // 0% 处的颜色
                      },
                      {
                        offset: 0.9,
                        color: "rgba(237,189,136,0)", // 100% 处的颜色
                      },
                    ],
                    globalCoord: false, // 缺省为 false
                  },
                },
              },
            },
          ],
        };
      },
      getFeeChartData(row) {
        let template = row.feeList;
        const feeLDate = template.map((item) => Object.keys(item)[0]);
        const feeLValue = template.map(
          (item) => item[Object.keys(item)[0]] * 100
        );
        let feeLTittle = row.name + "(" + row.ticker + ")";
        // const sharwOutstandingOption
        // 获取最大值
        var maxValue = Math.max.apply(null, feeLValue);

        // 获取最小值
        var minValue = Math.min.apply(null, feeLValue);
        return {
          title: {
            text: feeLTittle,
            left: "center",
            textStyle: {
              fontSize: 14,
              fontWeight: "normal",
              color: "rgba(209, 212, 220, 1)",
            },
            subtextStyle: {
              fontSize: 14,
            },
          },
          tooltip: {
            trigger: "axis",
            borderWidth: "0", //边框宽度设置1
            borderColor: "none", //设置边框颜色
            formatter: (params) => {
              let result = `<div style="border-bottom: 1px solid rgba(151, 151, 151, 0.2); padding-bottom: 8px; margin-bottom: 8px">
                    ${params[0].name}
                  </div>`;

              params.forEach((item) => {
                var aaa = item.value.toFixed(2);
                result += `<span style="color:#EACCAE">${item.seriesName} : ${aaa}%</span></br>`;
              });
              return result;
            },
            padding: 20,
            textStyle: this.textStyle,
            tooltip: {
              show: true,
            },
            extraCssText: "background:rgba(61, 63, 71, 1);",
          },
          grid: {
            top: "20%",
            left: "2%",
            right: "8%",
            bottom: "0",
            containLabel: true,
          },
          xAxis: [
            {
              type: "category",
              data: feeLDate,
              axisPointer: {
                type: "shadow",
              },
              axisTick: {
                show: false, // 隐藏y轴的刻度线
              },
              axisLabel: {
                showMaxLabel: true,
                // rotate: 60,
                textStyle: {
                  color: "rgba(209, 212, 220, 1)",
                },
                formatter: function (value) {
                  const parts = value.split(" ")[0].split("-");
                  return `${parts[1]}-${parts[2]}`; // 返回 'mm-dd' 格式
                },
              },
            },
          ],
          yAxis: [
            {
              name: "(%)",
              type: "value",
              nameTextStyle: {
                color: "#FFFFFF", // 这里使用十六进制颜色代码也可用 'white'
                fontSize: 12, // 你也可以设置字体大小等其他样式
                // 其他文本样式属性
              },
              // min: minValue,
              // max: maxValue,
              axisLabel: {
                formatter: function (v) {
                  if (v > 1000 && v <= 1000000) {
                    return v / 1000 + "K";
                  } else if (v > 1000000 && v <= 1000000000) {
                    return v / 1000000 + "M";
                  } else if (v > 1000000000) {
                    return v / 1000000000 + "B";
                  } else {
                    return v;
                  }
                  // return v + 'K';
                },
                fontWeight: "normal",
                fontSize: 12,
                textStyle: {
                  color: "rgba(209, 212, 220, 1)",
                },
                // margin: 20,
              },

              splitLine: {
                show: true,
                lineStyle: {
                  type: "dashed",
                  color: "rgba(255, 255, 255, 0.1)",
                },
              },
            },
          ],
          series: [
            {
              name: feeLTittle,
              type: "line",
              stack: "total",
              data: feeLValue,
              label: {
                show: false,
              },
              symbol: "circle",
              showSymbol: false,
              lineStyle: {
                color: "rgba(237, 189, 136, 1)",
              },
              itemStyle: {
                normal: {
                  color: "rgba(237, 189, 136, 1)",
                },
              },
              areaStyle: {
                normal: {
                  color: {
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0.1,
                        color: "rgba(237,189,136,0.2000)", // 0% 处的颜色
                      },
                      {
                        offset: 0.9,
                        color: "rgba(237,189,136,0)", // 100% 处的颜色
                      },
                    ],
                    globalCoord: false, // 缺省为 false
                  },
                },
              },
            },
          ],
        };
      },
    },
    mounted() {
      this.hDate = this.formatDate(new Date());
    },
  };
</script>


<style lang="scss">
  [dir="ltr"] .el-popper[x-placement^="bottom"] .popper__arrow {
    display: none !important;
  }

  [dir] .el-popper[x-placement^="bottom"] {
    margin-top: 4px !important;
  }

  @import "./scss/btcEtf.scss";
</style>